<template>
    <div class="page-wrap">
        <div class="session-form-hold">
            <div>
                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    top
                    color="primary"
                />
                <v-card-text class="text-center my-0 py-0">
                    <div style="display: flex; justify-content: center;">
                        <v-img
                            class="mb-2 px-4"
                            src="@/assets/img/logo-conecta.svg"
                            alt=""
                            max-width="180px"
                        />
                    </div>

                    <div class="mb-3">
                        <span
                            style="font-size: x-large;"
                            v-html="$t('users.login.title')"
                        />
                    </div>

                    <span class="red--text mb-2">
                        {{ serverError }}
                    </span>

                    <v-form
                        ref="form"
                        v-model="isValid"
                    >
                        <span />
                        <v-text-field
                            v-model="email"
                            solo
                            :label="$t('users.login.email')"
                            :rules="[rules.required, rules.validEmail]"
                            validate-on-blur
                            @keyup="serverError = ''"
                        />

                        <v-text-field
                            v-model="ePassword"
                            solo
                            :append-icon="showIcon ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showIcon ? 'text' : 'password'"
                            name="input-10-2"
                            :label="$t('users.login.password')"
                            :rules="[rules.required, rules.validPassword]"
                            validate-on-blur
                            @click:append="showIcon = !showIcon"
                            @keyup="serverError = ''"
                        />
                        <!--                        <v-checkbox
                            v-model="checkbox1"
                            :label="$t('users.login.rememberPass')"
                        />-->
                        <!--                      :style="`opacity: ${(!isValid && !serverCheck) ? '0.2' : '1'}`"-->
                        <v-btn
                            block
                            color="dark"
                            dark
                            @click="formSubmit"
                        >
                            <v-icon left>
                                mdi-login
                            </v-icon>
                            {{ $t('users.login.signIn') }}
                        </v-btn>

                        <div class="d-flex justify-center flex-wrap">
                            <v-btn
                                text
                                small
                                color="primary"
                                class="my-2"
                                to="/app/sessions/forgot"
                            >
                                {{ $t('users.login.forgotPassword') }}
                            </v-btn>
                            <strong
                                class="text-center red--text parpadea"
                                style="cursor: pointer"
                                @click="$router.push('/app/sessions/forgot')"
                            >
                                Si es la 1ra vez que accedes a esta app debes generar una contraseña haciendo click aquí
                            </strong>
                        </div>
                        <v-btn
                            v-if="canSignUp"
                            text
                            small
                            color="primary"
                            to="/app/sessions/sign-up-2"
                        >
                            {{ $t('users.login.createNewAccount') }}
                        </v-btn>
                    </v-form>
                </v-card-text>
            </div>
        </div>

        <v-img
            src="@/assets/img/background_person.png"
            alt=""
            max-height="35vh"
            contain
        />
    </div>
</template>
<script>

import { mapGetters, mapActions } from 'vuex'
import rules from '@/helpers/rulesValidate'
import axios from '@/plugins/axios'

export default {
    name: 'Login',
    metaInfo: {
        // title will be injected into parent titleTemplate
        // title: this.$t('users.login.title')
    },
    data() {
        return {
            isValid: true,
            serverCheck: false,
            serverError: '',
            showIcon: false,
            password: 'Password',
            checkbox1: true,
            checkbox2: false,
            email: '',
            ePassword: '',
            loading: false,
            rules: {
                ...rules
            },
            canSignUp: false
        }
    },
    computed: {
        ...mapGetters(['loggedInUser', 'error'])
    },
    created() {
        this.loadConfig();
    },
    methods: {
        ...mapActions(['login']),
        async loadConfig() {
            const configs = await axios
                .get('configs/1');

            let options = configs?.data?.data?.attributes?.options;
            if (options) {
                options = JSON.parse(options);
                this.canSignUp = options?.canRegister ?? false;
            }
        },

        async formSubmit() {
            if (!this.$refs.form.validate()) return false;

            const result = await this.login({
                email: this.email.trim(),
                password: this.ePassword
            });

            if (result !== true) {
                this.serverError = this.$t(`errors.${result}`);
            }
        }
    },
    watch: {
        // loading (val) {
        //   if (!val) return
        //   setTimeout(() => (this.loading = false), 2000)
        // },
        loggedInUser(val) {
            if (val && val.token && val.token.length > 0) {
                // this.makeToast("success", "Successfully Logged In");
                /* console.log('logged in successfully ') */
                this.loading = true
                setTimeout(() => {
                    /* console.log(val.roles)
                    console.log('val.roles') */
                    if (val.roles?.find(role => role.name === 'Admin')) {
                        this.$router.push('/app/admin')
                    } else {
                        this.$router.push('/')
                    }
                }, 500)
            }
        },
        error(val) {
            if (val != null) {
                // this.makeToast("warning", val.message);
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.page-wrap {
  background-color: #242939 !important;
  display: flex;
  flex-direction: column;
justify-content: space-between;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100%;

}

.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

</style>
